import React from 'react'
import { graphql, Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import Back from '../components/back';

const left = <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-left" class="svg-inline--fa fa-angle-left fa-w-8" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="currentColor" d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"></path></svg>;
const right = <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" class="svg-inline--fa fa-angle-right fa-w-8" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="currentColor" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path></svg>;
const arrow = {
  color: '#80808059',
  zIndex: 1,
  width: '7vw',
  position: 'fixed',
  top: '15vh'
};

class GridPostTemplate extends React.Component {
  render() {
    const post = get(this.props, 'data.contentfulPost');
    const edges = get(this.props, 'data.allContentfulPost.edges');
    const current = edges.find((edge) => {
      return edge.node.title === post.title;
    });

    if (current.previous) {
      console.log(current.previous.slug)
    }
    if (current.next) {
      console.log(current.next.slug)
    }
    const images = post.images && post.images.length ? post.images : [];
    const all = [post.heroImage].concat(images);
    return (
      <Layout location={this.props.location}>
        <div style={{ background: '#fff' }}>
          <Helmet title={`${post.title} | Dana Royal`} />
          
          <Back itemType={post.itemType}/>
          <div>
            <div>
              {current.previous ? <Link style={{...arrow, left: '5vw'}} to={`/${current.previous.slug}`}>
                {left}</Link> : ''
              }
 
              <ul className="article-list grid-list" style={{gridGap: '10vim', paddingTop: '3rem'}}>
                {all.map((img, i) => (<Img key={i} fixed={img.fixed} style={{ border: '1px solid #33333320'}} />))}
              </ul>

              {current.next ? <div style={{position: 'relative'}}><Link style={{...arrow, right: '5vw'}}  to={`/${current.next.slug}`}>
                {right}</Link></div> : ''
              }
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default GridPostTemplate

export const pageQuery = graphql`
  query GridPostBySlug($slug: String!, $itemType: String!) {
    contentfulPost(slug: { eq: $slug }) {
      title
      itemType
      heroImage {
        fixed(quality: 100) {
          ...GatsbyContentfulFixed
        }
      }
      images {
        fixed(quality: 100) {
          ...GatsbyContentfulFixed
        }
      }
    }
    allContentfulPost(sort: {fields: [priority]}, filter: {itemType: {eq: $itemType}}) {
      edges {
        node {
          title
        }
        previous {
          slug
        }
        next {
          slug
        }
      }
    }
  }
`
